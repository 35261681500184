import { FC, useState } from 'react'
import { Box, ListItemText, OutlinedInput, SelectProps } from '@mui/material'
import { SelectDropdownIcon } from '../../../components/svg'
import {
  CategoryMenuProps,
  DataSourceInputAdornment,
  IntegrationItem,
  LibraryFormControl,
  LibrarySelect,
  MenuItem
} from './library-components.styles'
import { CPIManifest, IntegrationInstance, IntegrationStatusMessage } from '../../../../models'
import DataSourceErrorTooltip from '../../../components/data-source-error-tooltip'

interface Props extends SelectProps {
  value: any
  handleChange: any
  dataSourceOptions: IntegrationInstance[]
  minWidth?: string
  manifestData: CPIManifest
  customClass?: string
  allOptionText?: string
  raise?: boolean
}

const LibraryDataSourceSelect: FC<Props> = ({
  handleChange,
  placeholder,
  dataSourceOptions,
  minWidth,
  value,
  raise,
  manifestData,
  customClass,
  allOptionText,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const getIntegrationLogo = (name: string) => {
    const findItem = manifestData.supported_integrations.find((item) => item.name === name)

    if (findItem) return `/icons/circle/${findItem.logo_url}`

    return '/crowdstrike_falcon_icon.svg'
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <LibraryFormControl iscustom={customClass ? 'true' : 'false'} sx={{ minWidth }}>
      <LibrarySelect
        iscustom={customClass ? 'true' : 'false'}
        isselected={placeholder !== 'Sort' && value.length > 1 ? 'true' : 'false'}
        labelId="library-data-source-select-label"
        id="library-data-source-select"
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          sx: {
            zIndex: raise ? 3001 : ''
          },
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null)
        }}
        IconComponent={(props) => {
          return <SelectDropdownIcon {...props} className={`${props.className} svg-dropdown`} />
        }}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }

          return selected
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        value={value}
        onChange={handleChange}
        input={
          <OutlinedInput
            onMouseDown={handleClick}
            startAdornment={
              <DataSourceInputAdornment position="start">
                <Box className="adornment-wrapper">
                  {value === allOptionText
                    ? dataSourceOptions
                        .filter((n) => n.name !== allOptionText)
                        .map((n) => {
                          if (n.syncStatus && n.syncStatus.status === IntegrationStatusMessage.failed) {
                            return (
                              <DataSourceErrorTooltip
                                displayName={n.integration_name}
                                errorMessage={n.syncStatus.errorMessage}
                                logoPath={`${getIntegrationLogo(n.integration_name).replace('/icons/circle/', '')}`}
                                instanceName={n.name}
                                id={n.id}
                                logoSize={18}
                                hideLink
                                isTrendsError
                                isCpiPage
                              />
                            )
                          }
                          return (
                            <IntegrationItem key={n.id} active={!n.deleted}>
                              <img
                                src={getIntegrationLogo(n.integration_name)}
                                alt={n.integration_name}
                                width="18px"
                                height="18px"
                              />
                              <span className="circle"></span>
                            </IntegrationItem>
                          )
                        })
                    : dataSourceOptions
                        .filter((n) => n.name === value)
                        .map((n) => {
                          if (n.syncStatus && n.syncStatus.status === IntegrationStatusMessage.failed) {
                            return (
                              <DataSourceErrorTooltip
                                displayName={n.integration_name}
                                errorMessage={n.syncStatus.errorMessage}
                                logoPath={`${getIntegrationLogo(n.integration_name).replace('/icons/circle/', '')}`}
                                instanceName={n.name}
                                id={n.id}
                                logoSize={18}
                                hideLink
                                isTrendsError
                                isCpiPage
                              />
                            )
                          }
                          return (
                            <IntegrationItem key={n.id} active={!n.deleted}>
                              <img
                                src={getIntegrationLogo(n.integration_name)}
                                alt={n.integration_name}
                                width="18px"
                                height="18px"
                              />
                              <span className="circle"></span>
                            </IntegrationItem>
                          )
                        })}
                </Box>
              </DataSourceInputAdornment>
            }
          />
        }
        sx={{ minWidth }}
      >
        {dataSourceOptions.map((option) =>
          option.name === allOptionText ? (
            <MenuItem key={option.id} value={option.name} onClick={handleClose} disabled={rest.disabled}>
              <Box className="image-wrapper">
                {dataSourceOptions
                  .filter((n) => n.name !== allOptionText)
                  .map((n) => (
                    <IntegrationItem
                      className="image-wrapper"
                      active={!n.deleted}
                      key={n.id}
                      iserrored={
                        n.syncStatus && n.syncStatus.status === IntegrationStatusMessage.failed ? 'true' : 'false'
                      }
                    >
                      <img
                        src={getIntegrationLogo(n.integration_name)}
                        alt={n.integration_name}
                        width="18px"
                        height="18px"
                      />
                    </IntegrationItem>
                  ))}
              </Box>
              <Box className="text-wrapper">
                <ListItemText primary={option.name} />
              </Box>
            </MenuItem>
          ) : (
            <MenuItem key={option.id} value={option.name} onClick={handleClose} disabled={rest.disabled}>
              <IntegrationItem
                className="image-wrapper"
                active={!option.deleted}
                iserrored={
                  option.syncStatus && option.syncStatus.status === IntegrationStatusMessage.failed ? 'true' : 'false'
                }
              >
                <img
                  src={getIntegrationLogo(option.integration_name)}
                  alt={option.integration_name}
                  width="18px"
                  height="18px"
                />
                <span className="circle-new"></span>
              </IntegrationItem>
              <Box className="text-wrapper">
                <ListItemText primary={option.name} />
              </Box>
            </MenuItem>
          )
        )}
      </LibrarySelect>
    </LibraryFormControl>
  )
}

export default LibraryDataSourceSelect
