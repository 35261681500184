import { FC } from 'react'
import { Box, IconButton, Typography } from '@mui/material'

/* Components */
import { Container } from './themeHeader.styles'
import ThemeButton from '../buttons/primary/ThemeButton'
import SaveSvg from '../svg/labels/SaveSvg'
import { TestStatus } from '../../pages/settings/integrations/SetupIntegrationInstance'

interface Props {
  headerTitle: string
  handleSave: () => void
  handleDiscard: () => void
  handleBack?: () => void
  testStatus: any
  isSubmitting: any
  isValid: any
  loading: any
  schema: any
  allowSave: boolean
  discardDisabled: boolean
}

const ThemeHeader: FC<Props> = ({
  headerTitle,
  handleSave,
  testStatus,
  isSubmitting,
  isValid,
  loading,
  schema,
  handleDiscard,
  handleBack,
  discardDisabled,
  allowSave
}) => {
  return (
    <Container>
      <Box className="wrapper">
        {typeof handleBack !== 'undefined' && (
          <Box className="back-arrow-wrapper">
            <IconButton onClick={handleBack}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 10.75H6.7875L13.775 3.7625L12 2L2 12L12 22L13.7625 20.2375L6.7875 13.25H22V10.75Z"
                  fill="#FFA9FC"
                />
              </svg>
            </IconButton>
          </Box>
        )}

        <Box className="header-content">
          <Typography className="header-title">{headerTitle}</Typography>
        </Box>

        <Box className="header-actions">
          <ThemeButton
            themevariant="primary"
            onClick={handleSave}
            disabled={
              testStatus !== TestStatus.successful || (!allowSave && (isSubmitting || !isValid || loading || !schema))
            }
          >
            <>
              <SaveSvg />
              Save
            </>
          </ThemeButton>
          <ThemeButton themevariant="secondary" onClick={handleDiscard} disabled={discardDisabled}>
            <>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                  fill="#FFA9FC"
                />
              </svg>
              Discard
            </>
          </ThemeButton>
        </Box>
      </Box>
    </Container>
  )
}

export default ThemeHeader
