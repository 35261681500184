import { FC, Fragment } from 'react'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import { Insight, InsightSubType } from '../../../../models'

/* Components */
import { Wrapper, CardHeader, CardFooter, FooterDivider } from './insightsCard.styles'
import { dateFormat } from '../../../../lib/utils'
import DataSources from '../../../components/data-sources'

interface Props {
  insight: Insight
  selected: boolean
  loadingMarking: boolean
  handleClickInsight: (insightId: string) => void
  search: string
}

const InsightsCard: FC<Props> = ({ insight, selected, loadingMarking, search, handleClickInsight }) => {
  if (!insight.data) return null
  const getTrendIcon = () => {
    switch (insight.sub_type) {
      case InsightSubType.CpiSlaOptimization:
        return (
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="4" fill="#AED581" />
            <path
              d="M10 25.5556V27.7778H16.6667V25.5556H10ZM10 12.2222V14.4444H21.1111V12.2222H10ZM21.1111 30V27.7778H30V25.5556H21.1111V23.3333H18.8889V30H21.1111ZM14.4444 16.6667V18.8889H10V21.1111H14.4444V23.3333H16.6667V16.6667H14.4444ZM30 21.1111V18.8889H18.8889V21.1111H30ZM23.3333 16.6667H25.5556V14.4444H30V12.2222H25.5556V10H23.3333V16.6667Z"
              fill="#292128"
            />
          </svg>
        )
      case InsightSubType.CrossedIntoGreen:
        return (
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="4" fill="#AED581" />
            <path
              d="M20 25.77L26.18 29.5L24.54 22.47L30 17.74L22.81 17.13L20 10.5L17.19 17.13L10 17.74L15.46 22.47L13.82 29.5L20 25.77Z"
              fill="#292128"
            />
          </svg>
        )
      case InsightSubType.CrossedIntoRed:
        return (
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="4" fill="#F09543" />
            <path
              d="M20.2222 30C21.4495 30 22.4444 29.0051 22.4444 27.7778C22.4444 26.5505 21.4495 25.5556 20.2222 25.5556C18.9949 25.5556 18 26.5505 18 27.7778C18 29.0051 18.9949 30 20.2222 30Z"
              fill="#292128"
            />
            <path d="M18 10H22.4444V23.3333H18V10Z" fill="#292128" />
          </svg>
        )
      case InsightSubType.Reduction:
        return (
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="4" fill="#FFE082" />
            <path
              d="M24 26L26.29 23.71L21.41 18.83L17.41 22.83L10 15.41L11.41 14L17.41 20L21.41 16L27.71 22.29L30 20V26H24Z"
              fill="#292128"
            />
          </svg>
        )
      case InsightSubType.Improvement:
      default:
        return (
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="4" fill="#AED581" />
            <path
              d="M24 14L26.29 16.29L21.41 21.17L17.41 17.17L10 24.59L11.41 26L17.41 20L21.41 24L27.71 17.71L30 20V14H24Z"
              fill="#292128"
            />
          </svg>
        )
    }
  }

  const highlightMatch = (text: string) => {
    if (!search) return text

    const regex = new RegExp(`(${search})`, 'gi')
    if (!text) return ''
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ color: '#FFA9FC' }}>
          {part}
        </span>
      ) : (
        <Fragment key={index}>{part}</Fragment>
      )
    )
  }

  return (
    <Wrapper
      className="insights-card-wrapper"
      isSelected={selected ? 'true' : 'false'}
      onClick={() => handleClickInsight(insight.id)}
      isloading={loadingMarking ? 'true' : 'false'}
    >
      <CardHeader className="card-header">
        <Box className="card-header-left">
          <Box className="icon-wrapper">
            {!insight.read && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="unread-indicator"
              >
                <circle cx="8" cy="8" r="6" fill="#FFE082" stroke={selected ? '#573F56' : '#372A36'} strokeWidth="4" />
              </svg>
            )}
            {getTrendIcon()}
          </Box>
          <Box className="card-header-wrapper">
            <Box className="card-header-top">
              <Typography className="insight-header-text">{highlightMatch(insight.data.header)}</Typography>
              <Typography className="insight-date-text">
                {dayjs(insight.data.insight_date).format(dateFormat.lastUpdateHour)}
              </Typography>
            </Box>
            <Typography className="insight-title-text">{highlightMatch(insight.data.title)}</Typography>
          </Box>
        </Box>
      </CardHeader>
      <CardFooter className="card-footer">
        <Box className="card-footer-left">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.66675 6.66669H5.23818V18.3334H1.66675V6.66669ZM8.33342 1.66669H11.6667V18.3334H8.33342V1.66669ZM15.0001 11.1905H18.3334V18.3334H15.0001V11.1905Z"
              fill="#D9BFD4"
            />
          </svg>
          <Typography className="cpi-name-text">
            {highlightMatch(insight.data.cpi_display_name)}
            {insight.data.section_card_label ? ' / ' : null}
            {insight.data.section_card_label ? highlightMatch(insight.data.section_card_label) : null}
          </Typography>
          <FooterDivider />
          <Box className="card-footer-right">
            <DataSources dataSources={insight.data.instances as any} isInsights />
          </Box>
        </Box>
      </CardFooter>
    </Wrapper>
  )
}

export default InsightsCard
