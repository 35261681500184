import { FormControl as MuiFormControl, TextField as MuiTextField } from '@mui/material'
import styled from '@emotion/styled/macro'

export const TextField = styled(MuiTextField)`
  //.MuiFormControl-root {
  //  width: 100%;
  //}
  //
  //.MuiFormHelperText-root {
  //  font-family: 'Quicksand', sans-serif;
  //  font-weight: 400;
  //  font-size: 12px;
  //  line-height: 16px;
  //  letter-spacing: 0.4px;
  //  color: #ffb4ab;
  //}
  //
  //.Mui-focused {
  //  fieldset {
  //    border-color: #ffa9fc !important;
  //  }
  //}
  //
  //.MuiInputLabel-root.Mui-focused {
  //  color: #ffa9fc !important;
  //}
  //
  //.MuiInputAdornment-root {
  //  .MuiTypography-root {
  //    font-family: 'Quicksand', sans-serif;
  //    font-weight: 400;
  //    font-size: 14px;
  //    line-height: 20px;
  //    letter-spacing: 0.25px;
  //    color: #ffffff;
  //  }
  //}
  //
  //.MuiInputBase-input {
  //  color: #e9e0e4;
  //}
`

export const FormControl = styled(MuiFormControl)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto !important;
  position: unset;
  gap: 8px;

  .form-control-label {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: unset;
  }

  .MuiOutlinedInput-root {
    width: 100%;

    .MuiOutlinedInput-input {
      &::placeholder {
        //overflow: hidden;
        //color: #867083;
        //text-overflow: ellipsis;
        //font-size: 16px;
        //font-style: normal;
        //font-weight: 400;
        //line-height: normal;

        overflow: hidden;
        color: #867083;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 1;
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiOutlinedInput-root {
      width: 100%;

      .MuiOutlineInput-input {
        &::placeholder {
          //overflow: hidden;
          //color: #867083;
          //text-overflow: ellipsis;
          //font-size: 16px;
          //font-style: normal;
          //font-weight: 400;
          //line-height: normal;

          overflow: hidden;
          color: #867083;
          text-overflow: ellipsis;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          opacity: 1;
        }
      }
    }
  }
`
