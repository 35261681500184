import { CPISeverity } from './CPILibrary'

export interface InsightDataRedirectionParam {
  cpi_name: string
  section: CPISeverity | null
  frequency: string
  time_period: string
}

export interface InsightDataInstance {
  id: string
  logo: string
  name: string
}

export interface InsightData {
  title: string
  section: CPISeverity | null
  section_card_display_name: string | null
  cpi_name: string
  cpi_display_name: string
  description: string
  instances: InsightDataInstance[]
  header: string
  change_value: number
  sla_value: number
  number_of_weeks_in_previous_zone: number
  comparison_period_end_date: string | null
  comparison_period_start_date: string | null
  card_image: string
  card_icon: string
  redirection_params: string
  insight_date: string | null
  section_card_label: string
}

export enum InsightSubType {
  Improvement = 'improvement',
  Reduction = 'reduction',
  CrossedIntoRed = 'cross_into_red',
  CrossedIntoGreen = 'cross_into_green',
  CpiSlaOptimization = 'cpi_sla_optimization'
}

export interface Insight {
  id: string
  type: string
  sub_type: string
  created_at: string
  data?: InsightData
  read: boolean
}

export enum InsightSortBy {
  InsightDate = 'insight_date',
  CPIName = 'cpi_name',
  Type = 'type'
}
